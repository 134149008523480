var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "mt-24 mb-16 sm:my-40", attrs: { id: "process-steps" } },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "steps flex sm:justify-between",
            class: { "full-width": _vm.fullWidth }
          },
          [
            _c(
              "div",
              {
                staticClass: "steps__step w-auto sm:w-full items-start done",
                on: {
                  click: function($event) {
                    return _vm.jumpStep(1)
                  }
                }
              },
              [
                _c("div", { staticClass: "steps__step__step-status" }, [
                  _vm._m(0),
                  _c("span", [_vm._v(_vm._s(_vm.$t("selectRoom")))]),
                  _c("i", {
                    staticClass: "owl-arrow-right text-grey block sm:hidden"
                  })
                ])
              ]
            ),
            _vm.hasAddons
              ? _c(
                  "div",
                  {
                    staticClass: "steps__step w-auto sm:w-full items-start",
                    class: { done: _vm.step > 2, active: _vm.step === 2 },
                    on: {
                      click: function($event) {
                        return _vm.jumpStep(2)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "steps__step__step-status" }, [
                      _vm._m(1),
                      _c("span", [_vm._v(_vm._s(_vm.$t("addAddons")))]),
                      _c("i", {
                        staticClass: "owl-arrow-right text-grey block sm:hidden"
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "steps__step w-auto sm:w-full items-start",
                class: { done: _vm.step > 3, active: _vm.step === 3 },
                on: {
                  click: function($event) {
                    return _vm.jumpStep(3)
                  }
                }
              },
              [
                _c("div", { staticClass: "steps__step__step-status" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "steps__step__step-status__circle hidden sm:block"
                    },
                    [
                      _c("label", { staticClass: "number block" }, [
                        _vm._v(_vm._s(_vm.hasAddons ? "3" : "2"))
                      ]),
                      _vm._m(2)
                    ]
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.$t("paymentInfo")))]),
                  _c("i", {
                    staticClass: "owl-arrow-right text-grey block sm:hidden"
                  })
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "steps__step flex-no-shrink items-center",
                class: { done: _vm.step > 4 },
                on: {
                  click: function($event) {
                    return _vm.jumpStep(4)
                  }
                }
              },
              [
                _c("div", { staticClass: "steps__step__step-status" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "steps__step__step-status__circle hidden sm:block"
                    },
                    [
                      _c("label", { staticClass: "number block" }, [
                        _vm._v(_vm._s(_vm.hasAddons ? "4" : "3"))
                      ]),
                      _vm._m(3)
                    ]
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.$t("completeOrder")))])
                ])
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "steps__step__step-status__circle hidden sm:block" },
      [
        _c("label", { staticClass: "number block" }, [_vm._v("1")]),
        _c("label", { staticClass: "check hidden" }, [
          _c("i", { staticClass: "owl-status-check" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "steps__step__step-status__circle hidden sm:block" },
      [
        _c("label", { staticClass: "number block" }, [_vm._v("2")]),
        _c("label", { staticClass: "check hidden" }, [
          _c("i", { staticClass: "owl-status-check" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "check hidden" }, [
      _c("i", { staticClass: "owl-status-check" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "check hidden" }, [
      _c("i", { staticClass: "owl-status-check" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }