<template>
  <section
    id="process-steps"
    class='mt-24 mb-16 sm:my-40'>
    <div class="container">
      <div
        :class='{"full-width": fullWidth}'
        class="steps flex sm:justify-between">
        <div
          class="steps__step w-auto sm:w-full items-start done"
          @click="jumpStep(1)">
          <div class="steps__step__step-status">
            <div class="steps__step__step-status__circle hidden sm:block">
              <label class='number block'>1</label>
              <label class='check hidden'>
                <i class="owl-status-check"></i>
              </label>
            </div>
            <span>{{ $t('selectRoom') }}</span>
            <i class="owl-arrow-right text-grey block sm:hidden"></i>
          </div>
        </div>
        <div
          v-if='hasAddons'
          class="steps__step w-auto sm:w-full items-start"
          :class="{'done': step > 2, 'active': step === 2}"
          @click="jumpStep(2)">
          <div class="steps__step__step-status">
            <div class="steps__step__step-status__circle hidden sm:block">
              <label class='number block'>2</label>
              <label class='check hidden'>
                <i class="owl-status-check"></i>
              </label>
            </div>
            <span>{{ $t('addAddons') }}</span>
            <i class="owl-arrow-right text-grey block sm:hidden"></i>
          </div>
        </div>
        <div
          class="steps__step w-auto sm:w-full items-start"
          :class="{'done': step > 3, 'active': step === 3}"
          @click="jumpStep(3)">
          <div class="steps__step__step-status">
            <div class="steps__step__step-status__circle hidden sm:block">
              <label class='number block'>{{ hasAddons ? '3' : '2' }}</label>
              <label class='check hidden'>
                <i class="owl-status-check"></i>
              </label>
            </div>
            <span>{{ $t('paymentInfo') }}</span>
            <i class="owl-arrow-right text-grey block sm:hidden"></i>
          </div>
        </div>
        <div
          class="steps__step flex-no-shrink items-center"
          :class="{'done': step > 4}"
          @click="jumpStep(4)">
          <div class="steps__step__step-status">
            <div class="steps__step__step-status__circle hidden sm:block">
              <label class='number block'>{{ hasAddons ? '4' : '3' }}</label>
              <label class='check hidden'>
                <i class="owl-status-check"></i>
              </label>
            </div>
            <span>{{ $t('completeOrder') }}</span>
          </div>
        </div>
      </div>
      </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
// import _ from 'lodash'
export default {
  name: 'process-steps',
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'hotelId',
      'step',
      'addon/addons',
      'hotel/isSkipAddon'
    ]),
    hasAddons: function () {
      return !this['hotel/isSkipAddon'] && this['addon/addons'].length !== 0
    }
  },
  methods: {
    jumpStep: function (targetStep) {
      if (targetStep < this.step) {
        switch (targetStep) {
          case 1:
            this.$router.push({
              name: 'Hotel',
              params: {
                hotelId: this.hotelId
              }
            })
            break
          case 2:
            this.$router.push({
              name: 'Addon',
              params: {
                hotelId: this.hotelId
              }
            })
            break
          case 3:
            this.$router.push({
              name: 'Payment',
              params: {
                hotelId: this.hotelId
              }
            })
            break
          case 4:
            this.$router.push({
              name: 'Complete',
              params: {
                hotelId: this.hotelId
              }
            })
            break
        }
      }
    }
  }
}
</script>

<style lang='sass' scoped>
.steps
  @apply w-full
  @screen lg
    width: calc(100% - 400px)
  &.full-width
    width: 100%
  &__step
    @apply flex flex-col text-center relative
    line-height: 22px

    @screen sm
      line-height: 27px
      &:not(:last-of-type)::before
        @apply absolute border-b-3 border-grey pin-l w-full pointer-events-none
        font-family: 'owl'
        content: ''
        z-index: 1
        top: 15px
        left: 30px
    &__step-status
      @apply flex items-center cursor-pointer
      @screen sm
        @apply flex-col
      label
        @apply border-3 mb-4 rounded-full text-grey relative cursor-pointer
        width: 32px
        height: 32px
        z-index: 2
        &.number
          @apply bg-white border-grey
        &.check
          @apply bg-green-dark border-green-dark text-white font-bold text-lg

      span
        @apply text-grey text-sm font-medium
        letter-spacing: 1px
        padding-left: 1px
        max-width: 80px

    &.done
      &::before
        @apply border-green-dark
      label
        &.number
          @apply hidden
        &.check
          @apply block

      span, i.owl-arrow-right
        @apply text-green-dark

    &.active
      label
        &.number
          @apply text-green-dark border-green-dark
      span
        @apply border-b-1 border-green-dark text-green-dark
        @screen sm
          @apply border-none
</style>
